<template>
  <b-card-code>
    <b-row>
      <b-col sm="6">
        <b-button-group>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-secondary"
            :disabled="loading"
            :to="'/portal/admin/auxiliary-list/'"
          >
            <feather-icon
              icon="ArrowLeftIcon"
              class="mr-50"
            />
            <span>Regresar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            :disabled="loading"
            :to="'/portal/admin/auxiliary-approvers-add/' + this.$route.params.id"
          >
            Agregar Aprobador
          </b-button>
        </b-button-group>
      </b-col>
      <b-col sm="6">
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-form-input
              v-model="searchTerm"
              placeholder="Buscar"
              type="text"
              class="d-inline-block"
              @keyup.enter="search(rows, searchTerm)"
            />
          </b-form-group>
          <b-form-group>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              :disabled="loading || searching"
              class="ml-1"
              @click="search(rows, searchTerm)"
            >
              <span v-if="!searching">Buscar</span>
              <span v-if="searching">Buscando...</span>
            </b-button>
          </b-form-group>
        </div>
      </b-col>

    </b-row>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :is-loading="loading"
      :rows="rows"
      :rtl="direction"
      :fixed-header="true"
      :max-height="screenHeight"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      >
      <div
        slot="emptystate"
        class="text-center"
      >
        No se encontraron datos
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Name -->
        <span
          v-if="props.column.field === 'fullName'"
          class="text-nowrap"
        >
          <b-avatar
            :src="props.row.avatar"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.fullName }}</span>
        </span>

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status.status'">
          <b-badge :variant="statusVariant(props.row.status.status)">
            {{ props.row.status.status }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="ListIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                v-if="props.row.status.status === 'Activo'"
                @click="inhabilitate(props.row.approverId, props.row.userId, 'X')"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>Deshabilitar </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="inhabilitate(props.row.approverId, props.row.userId, 'A')"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-50"
                />
                <span>Habilitar </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>


        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Mostrando 1 de
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['10','25', '50', '100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> de {{ props.total }} registros </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

  </b-card-code>
</template>

<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BRow, BCol,
  BButton, BDropdown, BDropdownItem, BButtonGroup
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import { downloadExcel } from '@core/utils/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store/index'
import environment from '@/environment'

export default {
  name: 'AuxiliaryApproval',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    BCardCode,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: true,
      searching: false,
      pageLength: 100,
      dir: false,
      columns: [
        {
          label: 'Usuario',
          field: 'userName',
        },
        {
          label: 'Nombre',
          field: 'userFullName',
        },
        {
          label: 'Email',
          field: 'userEmail',
        },
        {
          label: 'Posisión',
          field: 'userPosition',
        },
        {
          label: 'Auxiliar',
          field: 'auxCode',
        },
        {
          label: 'Estado',
          field: 'status.status',
        },
        {
          label: '',
          field: 'action',
          width: '100px',
          sortable: false,
        },
      ],
      rows: [],
      rowsFiltered: [],
      searchTerm: '',
      screenHeight: '',
      redirectOriginal: ''
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Preactivo: 'light-preactivoCda',
        Cerrado: 'light-secondary',
        Activo: 'light-primary',
        Inhabilitada: 'light-secondary'
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.load()
      this.screenHeight = (screen.height - 470) + 'px'
  },
  methods: {
    async load() {
      this.loading = true
      try {
        const resp = await this.$http.get(`${environment.uri}/auxiliaries/approvers/auxiliary/${this.$route.params.id}`)
        const statuses = this.$store.getters['catalog/allStatuses']

        if (resp && resp.data) {
          this.rows = resp.data.map(item => {
            const status = statuses.find(s => s.idStatus === item.status)
            return {
              ...item,
              status,
            }
          }).sort((a, b) => ((a.idGerencia > b.idGerencia) ? 1 : -1))
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.loading = false
      }
    },

    inhabilitate(approver, id, state) {
      this.loading = true
      this.errorMessage = ''
      this.$http.put(`${environment.uri}/auxiliaries/approver/update/id/${approver}`,{
        "userId": id,
        "auxId": this.$route.params.id,
        "status": state
      })
          .then(() => {
            this.loading = false
            this.load()
          })
          .catch(error => {
            console.error(error)
            this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
            this.loading = false
          })
    },

    download(rows) {
      const items = rows.map(item => ({
        periodo: (item.periodo || ''),
        idCentro: (item.idCentro || ''),
        unidad: (item.unidad || ''),
        descripcion: (item.descripcion || ''),
        status: (item.status.status || ''),
        creado: (item.creado || 0),
        aprobado: (item.aprobado || 0),
      }))
      downloadExcel(items, [
        'Periodo',
        'Id Centro',
        'Unidad',
        'Descripción',
        'Estado',
        'Creado',
        'Aprobado',
      ], 'report.csv')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
